<!--  -->
<!--这个页面实际上应该是个人端的预约界面，当时写在了小屋端，先不改了。
实际上小屋端还需要增加一些额外功能。
比如，小屋的预约主体仍是用户，由小屋进行的预约实际上是作为中转代替用户个人预约。因此预约过程和记录形式以后都要修改。
现在先拿这个跑通基础功能。-->
<template>
  <div class=''>
    <div style="font-size: 35px">预约远程诊疗</div>
    <el-divider></el-divider>
<!--    搜索框目前绑定的是医生姓名关键词，考虑增加/替换成科室关键词-->
    <el-input
        style="width: 30%"
        placeholder="请输入关键词查询"
        v-model="search"
        clearable>
    </el-input>
<!--<div>{{new Date(timeTableString2.cantOrderDate[0]).getTime()}}</div>
    <div>{{new Date(timeTableString2.avaDay[8]).getTime()}}</div>-->
<!--    <div>{{new Date(timeTableString2.cantOrderDate.replace(/-/g, '/'))}}</div>-->
<!--    <div>{{appointHisString.length}}</div>-->
<!--    <el-table :data="tableData.filter(data => !search || data.docName.toLowerCase().includes(search.toLowerCase()))"
              style="width: 100%">
&lt;!&ndash;      读取可预约医生信息&ndash;&gt;
      <el-table-column label="医生姓名" prop="docName"></el-table-column>
      <el-table-column label="称号" prop="docTitle"></el-table-column>

      <el-table-column label="简介" prop="docIntroduction">
        <template v-slot="scope1">
          <el-popover
              title="医生简介"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope1.row.docIntroduction}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="科室" prop="docDepartment"></el-table-column>
&lt;!&ndash;      可预约时间数据，目前倾向于和医生信息一起存储。此处页面里的日期和时间暂时没有写对应关系，实际上每个日期都单独对应其可选时间（以后再改）&ndash;&gt;
      <el-table-column label="可预约日期" prop="docDate" >
        <template v-slot="scope4">
          <el-select v-model="valueDate" placeholder="请选择">
            <el-option
                v-for="item in scope4.row.docDate"
                :key="item.label"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="可预约时间" prop="docOption">
        <template v-slot="scope2">
          <el-select v-model="valueTime" placeholder="请选择">
            <el-option
                v-for="item in scope2.row.docOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
&lt;!&ndash;      按钮弹出框套输入框，向预约记录提交&ndash;&gt;
      <el-table-column label="症状备注" prop="Remark" width="200%">
        <template v-slot="scope3">
          <el-input
              type="textarea"
              :rows="1"
              placeholder="请简要描述症状"
              v-model="scope3.row.textarea">
          </el-input>
        </template>
      </el-table-column>
&lt;!&ndash;      实际提交数据应包括：病人ID、医生ID、预约时间（暂时考虑存储为就诊日期、开始时间、结束时间三个数据）、症状备注（文本），以及随机生成一个预约编号&ndash;&gt;
      <el-table-column label="提交预约" prop="Submit">
&lt;!&ndash;        考虑到效率问题，应该限制单个用户的预约总量，这里还没有写，考虑读取用户的预约记录并限制已有多条预约未完成的用户提交新预约&ndash;&gt;
        <template>
          <el-button type="primary"
                     @click="open2">立即预约</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div>{{typeof reshapedData.canOrderDate}}</div>
    <div>{{valueDate}}</div>
    <el-divider></el-divider>
&lt;!&ndash;    <div v-bind="test001 = this.dayvalue(timeTableString2.canOrderDate)">{{test001}}</div>&ndash;&gt;

    <el-divider></el-divider>
&lt;!&ndash;    <div>{{this.timeTableString2.canOrderDate}}</div>&ndash;&gt;
&lt;!&ndash;    <el-table :data="timeTableString2.canOrderDate">
      <el-table-column label="日期" prop="appointDay">
      </el-table-column>
      <el-table-column label="时间" prop="appointTime.time1"></el-table-column>
      <el-table-column label="时间" prop="appointTime.time2"></el-table-column>
      <el-table-column label="时间" prop="appointTime.time3"></el-table-column>
    </el-table>&ndash;&gt;
    <div>{{reshapedData}}</div>-->
    <el-table :data="reshapedData.filter(data => !search || data.realName.toLowerCase().includes(search.toLowerCase()))">
      <el-table-column label="医生姓名" prop="realName"></el-table-column>
      <el-table-column label="称号" prop="title"></el-table-column>
      <el-table-column label="简介" prop="introduction">
        <template v-slot="scope10">
          <el-popover
              title="医生简介"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope10.row.introduction}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="科室" prop="department"></el-table-column>

      <el-table-column label="可预约时间" prop="appointDay">
        <template v-slot="scope11">
          <el-select v-model="scope11.row.selectedDay" v-bind:valueDate = scope11.row.selectedDay placeholder="请选择">
            <el-option
                v-for="item in scope11.row.avaDay"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="症状备注" prop="Remark" width="200%">
        <template v-slot="scope12">
          <el-input
              type="textarea"
              :rows="1"
              placeholder="请简要描述症状"
              v-model="scope12.row.textarea" v-bind:valueText=scope12.row.textarea>
          </el-input>
        </template>
      </el-table-column>

      <el-table-column label="提交预约" prop="Submit">
        <!--        考虑到效率问题，应该限制单个用户的预约总量，这里还没有写，考虑读取用户的预约记录并限制已有多条预约未完成的用户提交新预约-->
        <template v-slot="scope13">
          <span v-if="appointHisString.length<2">
          <el-button type="primary"
                     @click="appointmentSubmit(scope13.row)">提交</el-button>
            </span>
          <span v-else>
            <el-button type="primary"
                       @click="open2()">提交</el-button>
          </span>
        </template>
      </el-table-column>



<!--      <el-table-column label="可预约日期" prop="appointDay">
        <template v-slot="scope11" v-bind="scope11.row.avaDay=dayvalue(scope11.row.canOrderDate)">
          <el-select v-model="scope11.row.selectedDay" v-bind="valueDate=scope11.row.selectedDay" placeholder="请选择">
            <el-option
                v-for="item in scope11.row.canOrderDate"

                :key="item.appointDay"
                :label="typeof item.appointDay"
                :value="item.appointDay">
            </el-option>
          </el-select>
        </template>
      </el-table-column>-->


<!--      <el-table-column label="可预约时间" prop="appointTime">
        <template v-slot="scope12">
          <el-select v-model="valueTime" placeholder="请选择">
            <el-option
                v-for="item in scope12.row.canOrderDate"
                :key="item.appointTime"
                 :label="typeof item.appointTime"
                 :value="item.appointTime">
            </el-option>
          </el-select>
        </template>
      </el-table-column>-->




    </el-table>



  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Vue from 'vue'

/*const cors = require('koa2-cors');
app.use(cors());*/

export default {
//import引入的组件需要注入到对象中才能使用

  components: {},
  data() {
//这里存放数据
    return {
      search: '',
      valueText:'',
      valueDate:'',
      test001: [],

      userId: 1,
      userType: '小屋',
      uname: '心狗小屋',

      tableData: [{
        textarea: '',
        docName:'张伟',
        docTitle:'主任医师',
        docIntroduction:'毕业于XXX大学，至今已有XX年经验',
        docDepartment:'风湿',
        docDate:[{
          value: '22.7.11',
          label: '2022年7月11日'
        },{
          value: '22.7.12',
          label: '2022年7月12日'
        },{
          value: '22.7.13',
          label: '2022年7月13日'
        },{
          value: '22.7.14',
          label: '2022年7月14日'
        },{
          value: '22.7.15',
          label: '2022年7月15日'
        },],
        docOption:[{
          value: '9',
          label: '9:00-10:00'
        },{
          value: '10',
          label: '10:00-11:00'
        },],
        valueDate1:'',
      },{
        textarea: '',
        docName:'李强',
        docTitle:'副主任医师',
        docIntroduction:'XXXX年毕业于XXX大学，至今已有XX年经验',
        docDepartment:'消化',
        docDate:[{
          value: '22.7.11',
          label: '2022年7月11日'
        },{
          value: '22.7.12',
          label: '2022年7月12日'
        },{
          value: '22.7.15',
          label: '2022年7月15日'
        },{
          value: '22.7.16',
          label: '2022年7月16日'
        },],
        docOption:[{
          value: '14',
          label: '14:00-15:00'
        },{
          value: '15',
          label: '15:00-16:00'
        },{
          value: '16',
          label: '16:00-17:00'
        },],
        valueDate1:'',
      },],

      TT0:'"docId": 1, "canOrderDate": "[{ \\"appointDay\\": \\"2022-7-25\\", \\"appointTime\\": { \\"time1\\": \\"9:00\\", \\"time2\\": \\"10:00\\", \\"time3\\": \\"11:00\\" }},{ \\"appointDay\\": \\"2022-7-26\\", \\"appointTime\\": { \\"time1\\": \\"9:00\\", \\"time2\\": \\"10:00\\", \\"time3\\": \\"11:00\\" }},{ \\"appointDay\\": \\"2022-7-27\\", \\"appointTime\\": { \\"time1\\": \\"9:00\\", \\"time2\\": \\"10:00\\", \\"time3\\": \\"11:00\\" }},{ \\"appointDay\\": \\"2022-7-28\\", \\"appointTime\\": { \\"time1\\": \\"9:00\\", \\"time2\\": \\"10:00\\", \\"time3\\": \\"11:00\\" }},]", "realName": "心狗医生", "department": "心内科", "introduction": "从事心电诊断20年", "title": "主任医师"',
      timeTableGet:[],
      timeTableString:[],
      timeTableString1:[],
      timeTableString2:[],
      reshapedData: [],
      selectedData: [],
      appointHisString: [],
      /*reshapedData: [{
        realName:'',
        title: '',
        introduction: '',
        department: '',
        canOrderDate: [],
        teatArea:'',
      }],*/
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    unique(arr){
      return Array.from(new Set(arr))
    },

    open2() {
      this.$notify({
        title: '警告',
        message: '您已有两条未完成预约，暂时不可提交新的预约',
        type: 'warning'
      });
    },
    getDocInfo() {
      this.$http({
        url: this.$http.adornUrl('/enterprise/info/listCanOrder'),
        /*url: 'http://47.106.217.34:8445/heart-watchdog/doctor/canorderlist/listDetail',*/
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.timeTableString = data.list
          this.timeTableString1 = JSON.stringify(this.timeTableString)
          this.timeTableString1 = this.timeTableString1.slice(1)
          this.timeTableString1 = this.timeTableString1.slice(0,this.timeTableString1.length-1)
          this.timeTableString2 = JSON.parse(this.timeTableString1)
          Vue.set(this.timeTableString2,'selectedDay','')
          /*Vue.set(this.timeTableString2,'selectedTime','')*/
          Vue.set(this.timeTableString2,'avaDay',[])
          Vue.set(this.timeTableString2,'textarea','')


          /*this.timeTableString2.canOrderDate = JSON.stringify(this.timeTableString2.canOrderDate)*/
          /*this.timeTableString2.canOrderDate = this.timeTableString2.canOrderDate.slice(1)
          this.timeTableString2.canOrderDate = this.timeTableString2.canOrderDate.slice(0,this.timeTableString2.canOrderDate.length-1)*/
          this.timeTableString2.canOrderDate = JSON.parse(this.timeTableString2.canOrderDate)

          this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace('[','["')
          this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace(']','"]')
          this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.replace(',','","')
          this.timeTableString2.cantOrderDate = JSON.parse(this.timeTableString2.cantOrderDate)

          /*this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.slice(1)
          this.timeTableString2.cantOrderDate = this.timeTableString2.cantOrderDate.slice(0,this.timeTableString2.cantOrderDate.length-1)*/
          /*this.timeTableString2.cantOrderDate = "[&quot"*/
          /*this.timeTableString2.cantOrderDate = JSON.parse(this.timeTableString2.cantOrderDate)*/

          for (const item of this.timeTableString2.canOrderDate) {
            /*for (const Item in item.appointTime) {*/
              this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time1)
              this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time2)
              this.timeTableString2.avaDay.push(item.appointDay + " " + item.appointTime.time3)
              /*this.timeTableString2.avaDay.push(item.appointDay)
              this.timeTableString2.avaDay.push(item.appointDay)*/
            /*}*/
          }

          /*this.timeTableString2.avaDay=this.timeTableString2.avaDay.filter(this.timeTableString2.cantOrderDate)*/


              /*-this.timeTableString2.cantOrderDate*/

          /*this.timeTableString2.avaDay = this.timeTableString2.avaDay.filter(item=>{
            return this.timeTableString2.cantOrderDate.every(item2=>{
              return item != item2;
            })
          })*/
          /*new Date(timeTableString2.cantOrderDate.replace(/-/g, '/'))*/
          /*for(let i = this.timeTableString2.avaDay.length - 1; i >= 0; i--){
            for(let j = 0; j < this.timeTableString2.cantOrderDate.length;j++){
              if(new Date(this.timeTableString2.avaDay[i]) == new Date(this.timeTableString2.cantOrderDate[j])){
                this.timeTableString2.avaDay.splice(i,1);
              }
            }
          }*/

          for(let i in this.timeTableString2.avaDay){
            for(let t in this.timeTableString2.cantOrderDate){
              if(new Date(this.timeTableString2.avaDay[i]).getTime()==new Date(this.timeTableString2.cantOrderDate[t]).getTime()){
                this.timeTableString2.avaDay.splice(i,1)
              }
            }
          }

          /*this.timeTableString2.canOrderDate = JSON.stringify(this.timeTableString2.canOrderDate)
          this.timeTableString2.canOrderDate = this.timeTableString2.canOrderDate.slice(1)
          this.timeTableString2.canOrderDate = this.timeTableString2.canOrderDate.slice(0,this.timeTableString2.canOrderDate.length-1)
          this.timeTableString2.canOrderDate = JSON.parse(this.timeTableString2.canOrderDate)*/
          /*this.timeTableGet = JSON.parse(this.timeTableString);*/
          /*this.reshapedData = this.timeTableString2*/
          /*this.reshapedData.realName = this.timeTableString2.realName
          this.reshapedData.title = this.timeTableString2.title
          this.reshapedData.introduction = this.timeTableString2.introduction
          this.reshapedData.department = this.timeTableString2.department*/
          /*this.reshapedData.canOrderDate = this.timeTableString2.canOrderDate*/



          /*!!!这个地方使用for循环是为了把对象转成数组，el-table貌似识别不了数组！！！*/
          /*！！！只有一条数据的情况下for报错，回头记得测试多条数据的时候能不能正常使用！！！*/
          /*！！！千万千万别忘了！！！*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          /*for(let i in this.timeTableString2){*/
            this.reshapedData.push(this.timeTableString2)
            /*for (var item in this.reshapedData.canOrderDate) {
              for (var Item in item.appointTime) {
                this.reshapedData.avaDay.push(item.appointDay + " " + Item.time1)
                this.reshapedData.avaDay.push(item.appointDay + " " + Item.time2)
                this.reshapedData.avaDay.push(item.appointDay + " " + Item.time3)
              }
            }*/
            /*this.selectedData.push(this.timeTableString2.docId)
          /*}*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          /*！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！*/
          /*this.$set(this.reshapedData,"sex","男")*/
          /*Vue.set(this.reshapedData,'sex',"男")*/



          console.log("看看获取到的东西")
          console.log(data)
        } else {
          this.$message.error(data.msg)
        }
      })
      },

    appointmentSubmit(Obj){
      this.$http({
        url: this.$http.adornUrl('/enterprise/info/createOrder'),
        method: 'post',
            data: this.$http.adornData({
              userId: this.userId,
              userType: this.userType,
              uname: this.uname,
              docId: Obj.docId,
              symptoms: Obj.textarea,
              dateTime: Obj.selectedDay,
            })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看提交后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    appointHistoryGet(){
      this.$http({
        url: this.$http.adornUrl('/enterprise/info/listRecordByUid'),
        method: 'get',
        params: this.$http.adornParams({ userId: this.userId , userType: this.userType })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看预约数据")
          console.log(data)
          this.appointHisString = data.recordOrderList
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },


    dayvalue(Alist){
      let arr = []
        for(var i in Alist){
          arr.push(i.appointDay)
        }
        return arr
      },
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getDocInfo();
    this.appointHistoryGet();
    /*Vue.set(this.reshapedData,'selectedDay',"123")*/
    /*this.reshapedData.push({daySelected: ""});*/

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
